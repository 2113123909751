import { stAnalytics } from "@repo/analytics";
import { captureException } from "@repo/observability";
import { BrowserStorage } from "@repo/storage";
import { useNavigate, useSearchParams } from "@solidjs/router";
import { TbCircleCheck, TbReload } from "solid-icons/tb";
import { Match, Switch, createEffect } from "solid-js";
import { createStore } from "solid-js/store";
import { EmailField } from "@core/components/_original/EmailField";
import { StButton } from "@core/components/_original/StButton";
import { BackgroundBlobLeft } from "@core/marketing/components/BackgroundBlobLeft";
import { BackgroundBlobRight } from "@core/marketing/components/BackgroundBlobRight";
import { LandingPageNavbar } from "@core/marketing/components/LandingPageNavbar";
import { urls } from "@core/lib/urls";
import { useWire } from "@core/wire";

const Finish = () => {
  const wire = useWire();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [state, setState] = createStore({
    email: "",
    status: "idle" as "idle" | "emailFound" | "error" | "invalidUrl" | "needEmail" | "needEmailSubmitting",
  });

  /**
   * Redirects the user to the referrer URL if it exists, otherwise to the home page.
   *
   * **Implements tracking.**
   */
  const redirect = (status: string) => {
    return async () => {
      const referrer = BrowserStorage.getSignupReferrerUrl();
      stAnalytics.track("auth_finish_status", {
        email: state.email,
        status: status,
        signupReferral: !!referrer,
        signupReferralUrl: referrer ?? null,
      });

      // If there's an invite token, redirect user to accept invite
      const invite = params.invite;
      if (typeof invite === "string") {
        navigate(urls.acceptInvite(invite));
        return;
      }

      if (typeof params.collectionLink === "string") {
        navigate(urls.acceptCollectionShareableLink(params.collectionLink));
        return;
      }

      const [, { refetch: refetchCollections }] = wire.services.collections.globalResourceTree;
      const [, { refetch: refetchThreads }] = wire.services.threads.threadsListResource;
      await refetchCollections();
      await refetchThreads();
      const personalRoot = wire.services.collections.getPersonalRoot()?.id ?? "";

      //  1. If a user has come from within a collection or other, return to that collection
      if (referrer?.includes("app")) {
        navigate(referrer);
      }
      //  2. If a user has a root collection, prefer returning to that Collection.
      else if (personalRoot) {
        navigate(`/app/collection/${personalRoot}`);
      }
      //  3. Otherwise, if we have a referring page, return to it.
      else if (referrer) {
        navigate(referrer);
      }
      //  4. Otherwise return to the homepage.
      else {
        navigate("/");
      }
      BrowserStorage.removeSignupReferrerUrl();
    };
  };

  const onEmailEntered = (e: SubmitEvent) => {
    e.preventDefault();
    setState("status", "needEmailSubmitting");
    wire.services.auth.methods
      .finishSignInMagicLink(state.email, window.location.href)
      .then(redirect("needEmailSubmitting"))
      .catch((err) => {
        setState("status", "error");
        captureException(err);
        stAnalytics.track("error_auth", {
          email: state.email,
          error_source: "finish",
          flow: "magic-link",
          status: "needEmailSubmitting",
        });
      });
  };

  createEffect(() => {
    if (wire.services.auth.isConnecting()) {
      stAnalytics.track("auth_finish_status", {
        email: state.email,
        status: "invalid_state",
      });
      return;
    }
    const email = BrowserStorage.getLastUsedMagicLinkEmail();
    const url = window.location.href;
    const isValid = wire.services.auth.methods.isURLMagicLink(url);

    if (!isValid) {
      setState("status", "invalidUrl");
      stAnalytics.track("auth_finish_status", {
        email: state.email,
        status: state.status,
      });
      return;
    }

    if (!email) {
      setState("status", "needEmail");
      stAnalytics.track("auth_finish_status", {
        email: state.email,
        status: state.status,
      });
      return;
    }

    setState("email", email);

    wire.services.auth.methods
      .finishSignInMagicLink(email, url)
      .then(redirect("final"))
      .catch((err) => {
        setState("status", "error");
        captureException(err);
        stAnalytics.track("error_auth", {
          email: state.email,
          status: "final",
          error_source: "finish",
          flow: "magic-link",
        });
      });
  });

  const title = () => {
    if (state.status === "idle") return "Checking credentials...";
    if (state.status === "emailFound") return "Signing you in...";
    if (state.status === "error") return "Oops! Something went wrong";
    if (state.status === "invalidUrl") return "Invalid magic link";
    if (state.status === "needEmail" || state.status === "needEmailSubmitting") return "Please enter your email";
  };

  return (
    <div class="relative overflow-hidden h-screen w-screen flex flex-col">
      <BackgroundBlobLeft />
      <BackgroundBlobRight />

      <LandingPageNavbar static />

      <div class="p-10 sm:pt-20 relative">
        <div class="mx-auto max-w-lg sm:border border-violet-950/5 dark:border-indigo-950 dark:bg-indigo-700/5 dark:text-white rounded sm:px-10 sm:py-5">
          <div class="flex flex-col gap-4 sm:min-w-96">
            <div>
              <h1 class="text-xl leading-normal font-semibold bg-clip-text text-transparent bg-text-gradient-dark dark:bg-text-gradient inline">
                {title()}
              </h1>
            </div>

            <div class="w-full">
              <Switch>
                <Match when={state.status === "idle"}>
                  <p class="text-base leading-normal">Please don't close this page.</p>
                </Match>
                <Match when={state.status === "needEmail" || state.status === "needEmailSubmitting"}>
                  <p class="text-base leading-normal">
                    For security purposes we need the email address you used for this magic link.
                  </p>

                  <form class="pb-5 pt-10 w-full flex flex-col sm:flex-row gap-4 items-end" onSubmit={onEmailEntered}>
                    <EmailField
                      withIcon
                      email={state.email}
                      setEmail={(value: string) => setState("email", value)}
                      inputProps={{ autofocus: true }}
                    />

                    <StButton
                      icon={TbCircleCheck}
                      type="submit"
                      loading={state.status === "needEmailSubmitting"}
                      disabled={state.status === "needEmailSubmitting"}
                      class="flex-shrink-0 w-full sm:w-auto"
                    >
                      Confirm
                    </StButton>
                  </form>
                </Match>
                <Match when={state.status === "emailFound"}>
                  <p class="text-base leading-normal">You will be redirected in a second</p>
                </Match>
                <Match when={state.status === "invalidUrl"}>
                  <p class="mb-4 text-base leading-normal">We couldn't verify the link.</p>
                  <StButton href="/auth/login" icon={TbReload}>
                    Retry
                  </StButton>
                </Match>
                <Match when={state.status === "error"}>
                  <p class="mb-4 text-base leading-normal">We couldn't sign you in.</p>
                  <StButton href="/auth/login" icon={TbReload}>
                    Retry
                  </StButton>
                </Match>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finish;
